/** @format */

import { AbstractComponent } from "../AbstractComponent";
import $ from "jquery";

export class WideCarouselComponent extends AbstractComponent {
    public static selector: string = "wide-carousel-component";
    private scrollBoxElem: JQuery<HTMLElement>;
    private listElem: JQuery<HTMLElement>;
    private itemElems: JQuery<HTMLElement>;
    private moveLeftElem: JQuery<HTMLElement>;
    private moveRightElem: JQuery<HTMLElement>;
    private paginationElems: JQuery<HTMLElement>;

    private position: number = 0;
    private offset: number = 250;
    private translateWidth: number = 0;

    public init(): void {
        this.scrollBoxElem = this.componentElement.find(
            "[data-carousel-scroll-box]"
        );
        this.listElem = this.scrollBoxElem.find(">div");
        this.itemElems = this.listElem.find(">div");
        this.moveLeftElem = this.componentElement.find("[data-carousel-left]");
        this.moveRightElem = this.componentElement.find(
            "[data-carousel-right]"
        );
        this.paginationElems = this.componentElement.find(
            "[data-carousel-pagination] > span"
        );

        this.offset = this.listElem[0].scrollWidth / this.itemElems.length;
        this.translateWidth =
            this.listElem[0].scrollWidth - this.listElem[0].offsetWidth;

        this.setupListeners();
    }

    private setupListeners(): void {
        this.moveLeftElem.on("click", () => {
            this.moveList(-this.offset);
        });

        this.moveRightElem.on("click", () => {
            this.moveList(this.offset);
        });

        this.scrollBoxElem.on("scroll", () => {
            this.updatePagination();
            this.position = this.scrollBoxElem.scrollLeft();
        });
    }

    private moveList(amount: number): void {
        this.position += amount;
        this.moveLeftElem.addClass("active");
        this.moveRightElem.addClass("active");

        if (this.position <= 0) {
            this.position = 0;
            this.moveLeftElem.removeClass("active");
        } else if (this.position >= this.translateWidth) {
            this.position = this.translateWidth;
            this.moveRightElem.removeClass("active");
        }

        this.scrollBoxElem.animate({
            scrollLeft: this.scrollBoxElem.scrollLeft() + amount,
        });

        this.updatePagination();
    }

    private updatePagination(): void {
        const scrollLeft = this.scrollBoxElem.scrollLeft();
        const itemWidth = this.itemElems[0].offsetWidth;
        const currentItemIndex = Math.floor(scrollLeft / itemWidth);

        this.paginationElems.removeClass("active");
        this.paginationElems[currentItemIndex].classList.add("active");
    }
}
