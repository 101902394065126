/** @format */

import $ from "jquery";
import Cookies from "js-cookie";
import { sha256 } from "js-sha256";
import { pixelAPI } from "./requests/post";

declare const ttq;
declare const fbq;
declare const snaptr;
declare const gtag;

export function sleep(ms) {
    // await sleep(3000) in an async function will wait this amount of time
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function check_response(response: Response): Response {
    if (!response.ok) {
        throw new NotOkResponseError(response);
    } else {
        return response;
    }
}

export function getContext(): object {
    let element = document.getElementById("js-context");
    if (!element) {
        return {};
    } else {
        return JSON.parse(element.textContent);
    }
}

export function setupScroll(): void {
    $("[data-scroll-to]").on("click", event => {
        let destination = $(event.currentTarget).data("scroll-to");
        let element = $(destination)[0];
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    });

    /* data-scroll-into-view
     * On execution of the scrolling module, scroll this element instantly
     * into view.
     */
    let important = document.querySelector("[data-scroll-into-view]");
    if (important) {
        (important as HTMLElement).scrollIntoView();
    }
}

export function scrollToError(): void {
    let firstError = $(".error:visible").first();

    if (firstError.length == 0 || isInViewport(firstError)) {
        return;
    }

    window.scrollTo({ top: firstError.offset().top - 60, behavior: "smooth" });
}

export function getPadding(elem: JQuery<HTMLElement>, side: string): number {
    return parseInt(elem.css(`padding-${side}`).split("px")[0]);
}

export function scrollToTop(): void {
    if (navigator.userAgent.match(/(iPad|iPhone|iPod|Android)/g)) {
        window.scrollTo(0, 1);
    } else {
        $("html").scrollTop(1);
    }
}

export function replacePlaceholders(
    context: JQuery<HTMLElement>,
    answers: Object
) {
    let replace = $("[data-replace]", context);

    replace.each((index, elem) => {
        let petContainer = $(elem).closest("[data-pet-id]");
        let replaceWith = $(elem).attr("data-replace");
        let data = answers[replaceWith];

        if ([null, undefined].includes(data) && petContainer.length) {
            let petId = petContainer.data("pet-id");
            data = answers[`${replaceWith}_${petId}`];
        }

        if ([null, undefined].includes(data)) {
            return;
        }

        let replacedHTML = $(elem)
            .html()
            .split("$REPLACE")
            .join(data);

        $(elem).html(replacedHTML);
    });

    let replaceSelf = $("[data-replace-self]", context);

    replaceSelf.each((index, elem) => {
        let petContainer = $(elem).closest("[data-pet-id]");
        let replaceWith = $(elem).attr("data-replace-self");
        let data = answers[replaceWith];

        if ([null, undefined].includes(data) && petContainer.length) {
            let petId = petContainer.data("pet-id");
            data = answers[`${replaceWith}_${petId}`];
        }

        if ([null, undefined].includes(data)) {
            return;
        }

        for (let _ in elem.attributes) {
            let attr = elem.attributes[_];

            if (typeof attr === "object") {
                $(elem).attr(
                    attr.name,
                    attr.value.split("$REPLACE").join(data)
                );
            }
        }
    });
}

export class NotOkResponseError extends Error {
    response: Response;

    constructor(response: Response) {
        let url = response.url;
        let code = response.status;
        let description = response.statusText;
        super(`Response (${url}) was ${code} - ${description}`);

        this.response = response;
        this.name = "NotOkResponseError";
    }
}

export function copyToClipboard(text: string): void {
    let input = document.createElement("input");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
}

let timeout = null;
export function setupCopy(): void {
    const element = $("[data-copy]");
    const savedContent = element.html();

    element.on("click", event => {
        let text = $(event.currentTarget).data("copy");
        copyToClipboard(text);

        element.html("");
        element.addClass("copied");

        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            element.html(savedContent);
            element.removeClass("copied");
        }, 2000);
    });
}

export function setupShare(): void {
    $("[data-share]").on("click", event => {
        let data = $(event.currentTarget).data("share");

        if (!navigator["canShare"] || !navigator["canShare"](data)) {
            copyToClipboard(data.url);
        }

        try {
            navigator["share"](data);
        } catch (e) {
            if (e.toString().includes("AbortError")) {
                // User cancelled share
            }
        }
    });
}

export function urlToFile(url, filename, mimeType) {
    return fetch(url)
        .then(function(res) {
            return res.arrayBuffer();
        })
        .then(function(buf) {
            return new File([buf], filename, { type: mimeType });
        });
}

export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function isInViewport(element: JQuery<HTMLElement>): boolean {
    let elementTop = element.offset().top;
    let elementBottom = elementTop + element.outerHeight();

    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

export function ordinalSuffix(i) {
    let j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function deduplicate(array: Array<any>): Array<any> {
    return Array.from(new Set(array));
}

export function guff(length) {
    let result = "";
    let counter = 0;

    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    while (counter < length) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
    }

    return result;
}

export function getUrlParam(name: string): string {
    let url = new URL(window.location.href);
    return url.searchParams.get(name);
}

export function tiktokPixelIdentify(email: string, externalId: string) {
    if (!window["ttq"]) {
        return;
    }

    let hashedEmail = sha256(email.toLowerCase());
    let hashedExternalId = sha256(externalId);

    ttq.identify({
        email: hashedEmail,
        external_id: hashedExternalId,
    });
}

export async function firePixels(
    event: string,
    value?: number,
    email?: string
): Promise<void> {
    let eventId = guff(16);

    if (window["gtag"]) {
        let data = null;

        if (event === "Lead") {
            data = {
                send_to: "AW-11113616464/avFHCNfMlqcZENCosbMp",
            };
        } else if (event === "CompletePayment") {
            data = {
                send_to: "AW-11113616464/qDoXCOKYlqcZENCosbMp",
                value: value,
                currency: "GBP",
                transaction_id: eventId,
            };
        }

        if (data) {
            gtag("event", "conversion", data);
        }
    }

    if (window["fbq"]) {
        if (event === "CompletePayment") {
            event = "Purchase";
        } else if (event === "PlaceAnOrder") {
            event = "AddPaymentInfo";
        }
    }

    let result = await pixelAPI(event, eventId, value);

    if (!result["fire"]) {
        return;
    }

    if (window["ttq"]) {
        let contents = {};

        if (value) {
            contents["value"] = value;
            contents["currency"] = "GBP";
        }

        if (email) {
            contents["email"] = email;
        }

        ttq.track(event, contents, { event_id: eventId });
    } else if (window["fbq"]) {
        let contents = {};

        if (value) {
            contents["value"] = value;
            contents["currency"] = "GBP";
        }

        fbq("track", event, contents, { eventID: eventId });
    } else if (window["snaptr"]) {
        if (event === "CompletePayment") {
            event = "PURCHASE";
        }

        snaptr("track", event);
    }
}

export function getCSRFToken(): string {
    let cookie = Cookies.get("csrftoken");

    if (cookie) {
        return cookie;
    }

    let element = document.querySelector("[name=csrfmiddlewaretoken]");
    let csrf: string;

    if (element) {
        csrf = element.getAttribute("value");
    }

    if (csrf) {
        return csrf;
    } else {
        throw new Error("No CSRF token found.");
    }
}

export function localPrice(
    currency: string,
    price: number,
    convert: boolean = false
): string {
    let currencySymbol = "£";

    if (currency != "GBP") {
        currencySymbol = "$";
    }

    if (convert && currency == "CAD") {
        if (price == 2.95) {
            return "$4.95";
        } else if (price == 4.95) {
            return "$7.95";
        }
    }

    return `${currencySymbol}${price.toFixed(2)}`;
}

export function onIOS(): boolean {
    return (
        [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
}

export function truncate(str: string, n: number): string {
    return str.length > n ? str.substr(0, n - 1) + "&hellip;" : str;
}
