/** @format */

// If you imported it as Map, it would confuse Typescript's typing.
import { default as PureMap } from "core-js-pure/stable/map";
import { AbstractComponent } from "./AbstractComponent";

class DependencyInjector {
    public componentMap: Map<string, AbstractComponent> = new PureMap();
    public genericMap: Map<string, any> = new PureMap();

    public addComponentInstance(
        componentId: string,
        instance: AbstractComponent
    ): void {
        this.componentMap.set(componentId, instance);
    }

    public getComponentInstanceById(componentId: string): AbstractComponent {
        return this.componentMap.get(componentId);
    }

    public getComponentInstanceByElement(element: JQuery): AbstractComponent {
        let key = element.attr("component-id");
        return this.getComponentInstanceById(key);
    }

    /**
     * Returns all components of a given type.
     *
     * @param klass  The class of the instance type that you wish returned.
     *
     * @returns  An Array of component instances, in the order that they
     *           were originally added to the DependencyInjector.
     */
    public getComponentsOfType<T>(klass: {
        new (element: any, di: DependencyInjector): T;
    }): Array<T> {
        let components = [];

        this.componentMap.forEach((value, key) => {
            if ((value as any) instanceof klass) {
                components.push(value);
            }
        });

        return components;
    }

    public getGeneric(id: string): any {
        return this.genericMap.get(id);
    }
}

// TODO change to exporting only the type when prettier supports it
export { DependencyInjector };
export let DI = new DependencyInjector();
