/** @format */

import $ from "jquery";

let toggles = $("[data-toggle]");
let labels = $("[data-toggle-price]");
let isMonthly = true;

function setupListeners(): void {
    toggles.on("click", event => {
        if (
            $(event.currentTarget).is(".active:not(.pricing__toggle__clicker)")
        ) {
            return;
        }

        changePricing();
    });
}

function changePricing(): void {
    toggles.toggleClass("active");
    $("[data-toggle-annual]").toggle();

    labels.each((index, element) => {
        let price = $(element).data("price-mon");

        if (isMonthly) {
            price = $(element).data("price-ann");
        }

        $(element).text(`${price}`);
    });

    isMonthly = !isMonthly;
}

export function setup() {
    setupListeners();
}
