/** @format */

import { DI, DependencyInjector } from "./DI";

import $ from "jquery";
import { EventEmitter } from "events";

export abstract class AbstractComponent extends EventEmitter {
    public static selector: string = null;
    protected componentElement: any;
    protected componentParam: any;
    protected DI;

    public constructor(componentElement: any, DependencyInjector) {
        super();
        this.componentElement = componentElement;
        this.DI = DependencyInjector;
        return this;
    }

    public init(): void {}

    public getComponentClassName() {
        return this.constructor["name"];
    }

    public getComponentParameter() {
        this.componentParam = this.componentElement.attr(
            this.constructor["selector"]
        );

        return this.componentParam;
    }

    static getInst(elem?: JQuery<HTMLElement>): AbstractComponent | null {
        if (!elem) {
            elem = $(`[component-id="${this.selector}_0"]`);
        }

        return DI.getComponentInstanceByElement(elem) as AbstractComponent;
    }

    public getComponentElement() {
        return $(`[component-id="${this.getId()}"]`);
    }

    public getId(): string {
        return this.componentElement.attr("component-id");
    }

    public getIndex(): number {
        let id = this.getId();
        let number_string = id.split("_")[1];
        return parseInt(number_string);
    }
}

export function load(
    components: Array<{
        new (element: JQuery<HTMLElement>, di: DependencyInjector);
        selector: string;
    }>
): void {
    let instances = [];

    for (let component of components) {
        let elements = $(`[${component.selector}]`);
        let existing = elements.filter("[component-id]");
        let toInit = elements.not(existing);

        toInit.each(function(i, element) {
            let index = i + existing.length;
            let id = `${component.selector}_${index}`;
            $(element).attr("component-id", id);
            let instance = new component($(element), DI);
            DI.addComponentInstance(id, instance);
            instances.push(instance);
        });
    }

    for (const inst of instances) {
        inst.init();
    }
}

export class ComponentLoader {
    constructor(components) {
        load(components);
    }
}
