/** @format */

import { AbstractComponent } from "../AbstractComponent";
import { sleep } from "../toolkit";

export class ThreeSpinnerComponent extends AbstractComponent {
    public static selector: string = "three-spinner-component";
    private pointOne: JQuery<HTMLElement>;
    private pointTwo: JQuery<HTMLElement>;
    private pointThree: JQuery<HTMLElement>;

    public init(): void {
        this.pointOne = this.componentElement.find("[data-spinner-point='1']");
        this.pointTwo = this.componentElement.find("[data-spinner-point='2']");
        this.pointThree = this.componentElement.find(
            "[data-spinner-point='3']"
        );
    }

    private async animatePoint(
        point: JQuery<HTMLElement>,
        time: number
    ): Promise<void> {
        point.addClass("three-spinner__point--animated");

        let barElem = point.find("[data-bar]");
        barElem.animate({ width: "90%" }, { duration: time });

        await sleep(time);

        point.addClass("three-spinner__point--complete");
    }

    public async run(time: number = 12000): Promise<void> {
        await this.animatePoint(this.pointOne, time * 0.25);
        await sleep(time * 0.05);
        await this.animatePoint(this.pointTwo, time * 0.25);
        await sleep(time * 0.05);
        await this.animatePoint(this.pointThree, time * 0.25);
        await sleep(time * 0.15);
    }
}
