/** @format */

import "../polyfill.ts";

const pause = function(duration) {
    return new Promise(resolve => setTimeout(resolve, duration));
};

export function encodeFormData(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
}

export class NotOkResponseError extends Error {
    name: string;
    status: any;
    response: any;
    constructor(response) {
        let msg = `Bad response from '${response.url}': ${response.status}`;
        super(msg);
        this.name = "NotOkResponseError";
        this.response = response;
        this.status = response.status;
    }
}

class BadGatewayError extends NotOkResponseError {}

export class NoResponseError extends Error {
    name: string;
    response: any;
    constructor(response) {
        let msg = `No response from '${response.url}': retried too many times.`;
        super(msg);
        this.name = "NoResponseError";
        this.response = response;
    }
}

export interface RefetchOptions extends RequestInit {
    timeout?: number;
    attempts?: number;
    delay?: number;
    delay_extension?: number;
}

export async function refetch(url, options: RefetchOptions = {}) {
    /* re(try)fetch
     * Enhanced fetch, taking additional options:
     *  - timeout, number of milliseconds before the request will be
     *    aborted, rejecting the returned promise.
     *
     *  - attempts, number of attempts we'll make to the URL.
     *  Recieving a 502 from the server will count as a failed attempt,
     *  so we'll try again.
     *
     *  - delay, number of milliseconds to wait between attempts.
     *
     *  - delay_extension, number of milliseconds that are added to delay
     *    between attempts.
     */

    options = Object.assign(
        {
            timeout: 10000,
            attempts: 10,
            delay: 1000,
            delay_extension: 1000,
        },
        options
    );

    for (let i = 1; i <= options.attempts; i++) {
        const controller = new AbortController();
        options.signal = controller.signal;

        let abortTimeout;

        try {
            if (options.timeout) {
                abortTimeout = setTimeout(() => {
                    controller.abort();
                }, options.timeout);
            }

            let response = await fetch(url, options);
            let retry = false;
            let clone = response.clone();

            try {
                let responseJson = await clone.json();

                if (responseJson && responseJson["retry"]) {
                    retry = true;
                }
            } catch (err) {
                // ignore
            }

            if (response.status in [502, 503, 504]) {
                clearTimeout(abortTimeout);
                throw new BadGatewayError(response);
            }

            if (!retry) {
                return response;
            } else if (i === options.attempts) {
                throw new NoResponseError(response);
            }
        } catch (err) {
            if (err.name !== "AbortError") {
                throw err;
            }
        }

        if (i !== options.attempts) {
            await pause(options.delay);
            options.delay += options.delay_extension;
        }
    }
}
