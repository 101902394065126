/** @format */

import "../polyfill";
import $ from "jquery";
import "jquery-validation";
import "../extra_validators";

import { createAccount } from "../requests/post";
import {
    firePixels,
    getContext,
    getUrlParam,
    ordinalSuffix,
    scrollToError,
} from "../toolkit";
import { BumperComponent } from "../components/BumperComponent";
import { LightboxComponent } from "../components/LightboxComponent";
import { Chive, ChiveError } from "../chargehive";
import { petNames } from "../requests/save";
import { ComponentLoader } from "../AbstractComponent";

new ComponentLoader([BumperComponent]);

let hasAccount = getContext()["has_account"];
let chiveInst: Chive;
let currency = getContext()["currency"] || "GBP";

let form = $("[data-checkout-form]");
let nameField = $("[name=name]", form);
let billingForm = $("[data-billing-form]");
let buttonElem = $("[data-checkout]");
let savedContent: string;
let changedField = false;

let quantity = 1;

function validate() {
    (form as any).validate({
        onKeyup: true,
        rules: {
            pet_name: {
                required: true,
                minlength: 2,
                notInOtherInput: "name",
            },
            name: {
                required: true,
                fullname: true,
                noTitles: true,
            },
            email: {
                required: true,
                email: true,
            },
            line_1: {
                required: true,
                minlength: 3,
            },
            city: {
                required: true,
                minlength: 3,
            },
            state: {
                required: true,
                minlength: 2,
            },
            postal_code: {
                required: true,
                postcode: true,
            },
            billing_line_1: {
                required: true,
                minlength: 3,
            },
            billing_city: {
                required: true,
                minlength: 3,
            },
            billing_state: {
                required: true,
                minlength: 2,
            },
            billing_postal_code: {
                required: true,
                postcode: true,
            },
            terms: {
                required: true,
            },
        },
    } as any);

    ($ as any).validator.addClassRules("petNameValidation", {
        required: true,
        minlength: 2,
        maxlength: 64,
        noDuplicates: "pet_name",
        lettersOnly: true,
        hasLetters: true,
    });
}

function setupListeners() {
    form.on("submit", event => submit());

    buttonElem.on("click", async () => submit());

    $("[name=billing_same]").on("change", () => {
        billingForm.toggle();
    });

    nameField.on("change", () => {
        chiveInst.setCardName(nameField.val() as string);

        if (!changedField) {
            firePixels("InitiateCheckout");

            changedField = true;
        }
    });

    if (chiveInst) {
        chiveInst.on("success", () => {
            LightboxComponent.getDisplay("payment-success");
        });

        chiveInst.on("declined", () => {
            LightboxComponent.getDisplay(
                "Transaction declined. Please try another payment method.",
                true
            );
            buttonElem.html(savedContent);
        });

        chiveInst.on("error", () => {
            LightboxComponent.getDisplay(
                "Transaction failed. Please try again.",
                true
            );
            buttonElem.html(savedContent);
        });

        chiveInst.on("cancel", () => {
            LightboxComponent.getDisplay(
                "Transaction cancelled. Please try again.",
                true
            );
            buttonElem.html(savedContent);
        });
    }

    $("[data-apple-pay]").on("click", async () => {
        chiveInst.setPaymentMethod("PLACEMENT_CAPABILITY_APPLE_PAY");
        chiveInst.applePaySubmit(quantity);
    });

    $("[lightbox-component]").on(
        "click",
        "[data-submit-pet-name]",
        async event => {
            let form = $("[data-pet-name-form]");

            if (!(form as any).valid()) {
                scrollToError();
                return;
            }

            let fields = $("[name^='pet_name']").filter(":visible");
            let petNamesArr = fields.map(
                (index, elem) => $(elem).val() as string
            );

            await petNames(petNamesArr.toArray());

            window.location.href = "/thank-you";
        }
    );
}

async function submit() {
    if (buttonElem.find(".loader").length) {
        return;
    }

    savedContent = buttonElem.html();
    buttonElem.html("<div class='loader'></div>");

    if (!(form as any).valid()) {
        buttonElem.html(savedContent);
        scrollToError();
        return;
    }

    setTimeout(() => {
        LightboxComponent.getDisplay("payment-processing");
    }, 2000);

    let name = nameField.val() as string;
    let email = $("[name=email]").val() as string;
    let line1 = $("[name=line_1]").val() as string;
    let city = $("[name=city]").val() as string;
    let state = $("[name=state]").val() as string;
    let postcode = $("[name=postal_code]").val() as string;
    let billingLine1;
    let billingCity;
    let billingState;
    let billingPostcode;

    if ($("[name=billing_same]").prop("checked") != true) {
        billingLine1 = $("[name=billing_line_1]").val() as string;
        billingCity = $("[name=billing_city]").val() as string;
        billingState = $("[name=billing_state]").val() as string;
        billingPostcode = $("[name=billing_postal_code]").val() as string;
    }

    if (!hasAccount) {
        firePixels("PlaceAnOrder");

        await createAccount(
            [$("[name=pet_name]").val() as string],
            name,
            email,
            "",
            false,
            currency,
            getContext()["vet_first"],
            line1,
            city,
            state,
            postcode,
            billingLine1,
            billingCity,
            billingState,
            billingPostcode,
            true
        );

        hasAccount = true;
    }

    await chiveInst.setupCharge();
    await chiveInst.setPersonalInfo(name, email, false);
    await chiveInst.setBillingAddress(
        billingLine1,
        billingCity,
        billingState,
        billingPostcode
    );

    await chiveInst.submit().catch(error => {
        buttonElem.html(savedContent);
        scrollToError();

        if (error instanceof ChiveError) {
            try {
                for (let item of JSON.parse(error.message)) {
                    Chive.addError(item);
                }
            } catch (e) {
                LightboxComponent.getDisplay(error.message, true);
            }

            return;
        } else {
            throw error;
        }
    });
}

export async function setup() {
    if (form.length || $("[data-apple-pay]").length) {
        chiveInst = new Chive();
        chiveInst.setupCharge();
    }

    validate();
    setupListeners();
    firePixels("ViewContent");

    if (document.querySelector("[data-help-content='lp-loader']")) {
        LightboxComponent.getDisplay("lp-loader");
    }
}
