/** @format */

import $ from "jquery";
import from from "core-js-pure/features/array/from";

// Requires "classlist-polyfill" in order to work on IE11

export class Question {
    element: HTMLElement;
    questions: Array<Question>;

    head_element: HTMLElement;
    answer_element: HTMLElement;

    constructor(element: HTMLElement, questions: Array<Question>) {
        this.element = element;
        this.questions = questions;

        this.head_element = element.querySelector("[data-faq-question-head]");
        this.answer_element = element.querySelector(
            "[data-faq-question-answer]"
        );

        if (this.head_element !== null) {
            this.head_element.addEventListener("click", event => {
                this.click(event);
            });
        }
    }

    get active(): boolean {
        return this.element.classList.contains("active");
    }
    set active(value: boolean) {
        this.element.classList.toggle("active", value);
        this.element.classList.toggle("v12-faqs__entry-active", value);
        if (value) {
            // Show the body of the question
            $(this.answer_element).slideDown();

            let url = this.element.dataset.url;

            if (url) {
                // Set the address bar for URL positive reasons
                history.replaceState({}, "", url);
            }

            // Close all other "active" questions when a new
            // one becomes active.
            for (const question of this.questions) {
                if (question === this) {
                    continue;
                }

                question.active = false;
            }
        } else {
            // Hide the body of the question
            $(this.answer_element).slideUp();
        }
    }
    click(event: MouseEvent) {
        this.active = !this.active;
    }
}

export function setup() {
    let questions = [];
    let elements = document.querySelectorAll("[data-faq-question]");

    for (const element of from(elements)) {
        let html_element = element as HTMLElement;
        let q = new Question(html_element, questions);
        questions.push(q);
    }
}
