/** @format */

import $ from "jquery";

export function run() {
    $("[data-scroll-to]").on("click", event => {
        let destination = $(event.currentTarget).data("scroll-to");
        let offset = $(event.currentTarget).data("scroll-offset") || 0;

        let target = $(destination);
        if (target.length > 0) {
            $("html, body").animate(
                {
                    scrollTop: target.offset().top + offset,
                },
                500
            );
        }
    });

    /* data-scroll-into-view
     * On execution of the scrolling module, scroll this element instantly
     * into view.
     */
    let important = document.querySelector("[data-scroll-into-view]");
    if (important) {
        (important as HTMLElement).scrollIntoView();
    }
}
