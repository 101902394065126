/** @format */

import { AbstractComponent } from "../AbstractComponent";
import $ from "jquery";
import { ordinalSuffix } from "../toolkit";

export class BumperComponent extends AbstractComponent {
    public static selector: string = "bumper-component";
    private quantity: number = 1;
    private quantityElem: JQuery<HTMLElement>;
    private quantityBumpers: JQuery<HTMLElement>;

    public init(): void {
        this.quantityElem = this.componentElement.find("[data-quantity]");
        this.quantityBumpers = this.componentElement.find(
            "[data-quantity-bumper]"
        );

        this.setupListeners();
    }

    private setupListeners(): void {
        this.quantityBumpers.on("click", event => {
            let direction = $(event.currentTarget).data("quantity-bumper");

            if (direction == "up") {
                this.quantity = Math.min(5, this.quantity + 1);
                this.addNameField();
            } else {
                this.quantity = Math.max(1, this.quantity - 1);
                this.removeNameField();
            }

            this.setQuantity(this.quantity);
            this.emit("quantityChange", this.quantity);

            let otherBumpers = $(`[${BumperComponent.selector}]`).not(
                this.componentElement
            );

            otherBumpers.each((index, elem) => {
                let bumperInst = this.DI.getComponentInstanceByElement($(elem));
                bumperInst.quantity = this.quantity;
                bumperInst.setQuantity(this.quantity);
            });
        });
    }

    private setQuantity(quantity: number): void {
        let singlePetElems = $("[data-single-pet]");
        let multiPetElems = $("[data-multi-pet]");

        if (this.quantity == 1) {
            singlePetElems.show();
            multiPetElems.hide();
        } else {
            singlePetElems.hide();
            multiPetElems.show();
        }

        this.quantityElem.text(this.quantity);
    }

    private addNameField(): void {
        let nameFields = this.getNameInputs();
        let fieldNumber = nameFields.length;

        if (fieldNumber == 0 || fieldNumber >= 5) {
            return;
        }

        let dupeHTML = nameFields[0].outerHTML.replace(
            new RegExp(`pet_name_0`, "g"),
            `pet_name_${fieldNumber}`
        );
        let dupeElem = $(dupeHTML);

        dupeElem.attr(
            "placeholder",
            `${ordinalSuffix(fieldNumber + 1)} Pet Name`
        );
        nameFields.last().after(dupeElem);

        if (fieldNumber == 1) {
            nameFields.first().attr("placeholder", "1st Pet Name");
        }
    }

    private removeNameField(): void {
        let nameFields = this.getNameInputs();
        let fieldNumber = nameFields.length;

        if (fieldNumber == 0 || fieldNumber == 1) {
            return;
        }

        nameFields.last().remove();

        if (fieldNumber == 2) {
            nameFields.first().attr("placeholder", "Pet Name");
        }
    }

    private getNameInputs(): JQuery<HTMLElement> {
        return $("[name^='pet_name']");
    }
}
