/** @format */

import $ from "jquery";
import Swipy from "swipyjs";
import { getContext, sleep } from "../toolkit";

let container: JQuery<HTMLElement>;
let items: JQuery<HTMLElement>;
let index: number;
let inProgress = false;

async function changeLocation(oldIndex: number, newIndex: number) {
    let locationElems = $("[data-carousel-location] div");

    if (locationElems.length === 0) {
        return;
    }

    let oldLocation = locationElems.eq(oldIndex);
    let newLocation = locationElems.eq(newIndex);

    oldLocation.removeClass("active");
    await sleep(500);
    newLocation.addClass("active");
}

async function changeContext(index: number) {
    let context = getContext()["carousel"];
    let contextElems = $("[data-carousel-context]");

    if (contextElems.length === 0) {
        return;
    }

    contextElems.fadeOut(500);
    await sleep(500);

    for (let i = 0; i < contextElems.length; i++) {
        let elem = contextElems.eq(i);
        let contextKey = elem.data("carousel-context");
        let contextValue = context[index][contextKey];

        if (elem.is("img")) {
            elem.attr("src", contextValue);
        } else {
            elem.text(contextValue);
        }
    }

    let dataAttribute = "data-carousel-context-data";
    let contextDataElems = $(`[${dataAttribute}]`);

    for (let i = 0; i < contextDataElems.length; i++) {
        let elem = contextDataElems.eq(i);
        let contextValue = context[index]["data"] || "";

        elem.attr(dataAttribute, contextValue);
    }

    contextElems.fadeIn(500);
}

function move(adjustment: number) {
    if (inProgress) {
        return;
    }

    inProgress = true;

    let new_index = index + adjustment;
    if (new_index >= items.length) {
        new_index = 0;
    }
    if (new_index < 0) {
        new_index = items.length - 1;
    }

    $(items).fadeOut(500);

    $(items)
        .eq(new_index)
        .delay(500)
        .fadeIn(500, () => {
            inProgress = false;
            index = new_index;
        });

    changeLocation(index, new_index);
    changeContext(new_index);
}

export function setup(root: HTMLElement) {
    /** Attaches "carousel-like" behaviour to a given element.
     *
     * <section>
     *   <div data-carousel-container>
     *     <p data-carousel-item style="display: block;">First</p>
     *     <p data-carousel-item style="display: none;">Second</p>
     *     <p data-carousel-item style="display: none;">Third</p>
     *   </div>
     *   <span data-carousel-controls="right">FORWARD</span>
     *   <span data-carousel-controls="left">BACKWARD</span>
     * </section>
     *
     * When an element with "[data-carousel-controls="right|left"]" is clicked,
     * the current element displayed is hidden, and then the next/previous
     * element in the set of "[data-carousel-container] [data-carousel-item]"
     * is displayed with inline style attributes. Going backwards and
     * forwards at the start and end of the collection of items is handled.
     *
     */
    let elem = $(root);

    container = elem.find("[data-carousel-container]");

    if (container.length === 0) {
        container = elem;
    }

    items = container.find("[data-carousel-item]");
    let visibleItem = items.filter(":visible");
    index = items.index(visibleItem);

    let naturalMove = setInterval(() => {
        move(1);
    }, 10000);

    $(root).on(
        "click",
        "[data-carousel-controls]",
        (event: JQuery.TriggeredEvent) => {
            let direction = event.currentTarget.dataset.carouselControls;
            let adjustment = 0;
            if (direction == "left") {
                adjustment = -1;
            } else if (direction == "right") {
                adjustment = 1;
            }

            move(adjustment);
            clearInterval(naturalMove);
        }
    );

    let swipy = new Swipy(root);

    swipy.on("swipeleft", () => {
        move(1);
        clearInterval(naturalMove);
    });

    swipy.on("swiperight", () => {
        move(-1);
        clearInterval(naturalMove);
    });
}
