import $ from "jquery";

const activeClass = 'active'
const sidebarItems = $('.dashboard-sidebar__section li')
let index = 0

sidebarItems.each(idx => {
  sidebarItems[idx].addEventListener('click', () => {
    sidebarItems[index].classList.remove(activeClass)
    index = idx
    sidebarItems[index].classList.add(activeClass)
  })
})