/** @format */

import { refetch, RefetchOptions } from "./refetch";
import { check_response } from "./toolkit";
import { getCSRFToken } from "../toolkit";

export async function genericSave(
    url: string,
    payload: object | FormData = {}
): Promise<Response> {
    let options: RefetchOptions = {
        method: "post",
        headers: {
            "X-CSRFToken": getCSRFToken(),
        },
        credentials: "same-origin",
    };

    if (payload instanceof FormData) {
        options["body"] = payload;
    } else {
        options["headers"]["Content-Type"] = "application/json; charset=utf-8";
        options["body"] = JSON.stringify(payload);
    }

    return refetch(`${window.location.origin}${url}`, options)
        .then(check_response)
        .then(response => response.json());
}

export async function address(
    name: string,
    line1: string,
    city: string,
    state: string,
    postalCode: string,
    primary: boolean = false,
    billing: boolean = false,
    address_id?: string,
    line_2?: string,
    verify_token?: string
): Promise<Response> {
    let body = {
        name: name,
        line_1: line1,
        city: city,
        state: state,
        postal_code: postalCode,
        primary: primary,
        billing: billing,
    };

    if (address_id) {
        body["address_id"] = address_id;
    }

    if (line_2) {
        body["line_2"] = line_2;
    }

    if (verify_token) {
        body["verify_token"] = verify_token;
    }

    return genericSave("/api/account/save-address", body);
}

export async function addressFromId(address_id: string): Promise<object> {
    let body = {
        address_id: address_id,
    };

    let response = await genericSave("/api/account/save-address-from-id", body);

    return response["address"];
}

export async function addressLazy(
    line_1: string,
    postal_code: string
): Promise<object> {
    let body = {
        line_1: line_1,
        postal_code: postal_code,
    };

    let response = await genericSave("/api/account/save-address-lazy", body);

    return response["address"];
}

export async function addressSwitchPrimary(
    old_primary_id: string,
    new_primary_id: string
): Promise<Response> {
    return genericSave("/api/account/switch-primary-address", {
        old_primary_id: old_primary_id,
        new_primary_id: new_primary_id,
    });
}

export async function answers(
    pet_id: string,
    answers: Object,
    question?: string,
    reached?: string
): Promise<Response> {
    let url = `/api/pet/${pet_id}/save-answers`;

    let payload = {
        answers: answers,
    };

    if (question !== null) {
        payload["current_question"] = question;
    }

    if (reached) {
        payload["reached"] = reached;
    }

    return genericSave(url, payload);
}

export async function email(email: string): Promise<Response> {
    return genericSave("/api/account/save-email", { email: email });
}

export async function name(name: string): Promise<Response> {
    return genericSave("/api/account/save-name", { name: name });
}

export async function contact(
    phone: string,
    primary: boolean = true,
    name?: string,
    contact_reference?: string
): Promise<Response> {
    let body = {
        phone: phone,
        primary: primary,
    };

    if (name) {
        body["name"] = name;
    }

    if (contact_reference) {
        body["contact_reference"] = contact_reference;
    }

    return genericSave("/api/account/save-contact", body);
}

export async function information(
    email: string,
    name?: string,
    first_name?: string,
    last_name?: string
): Promise<Response> {
    let body = {
        email: email,
    };

    if (name) {
        body["name"] = name;
    }

    if (first_name && last_name) {
        body["first_name"] = first_name;
        body["last_name"] = last_name;
    }

    return genericSave("/api/account/save-information", body);
}

export async function contactPreferences(
    text: boolean,
    email: boolean
): Promise<Response> {
    return genericSave("/api/account/save-contact-preferences", {
        text: text,
        email: email,
    });
}

export async function password(
    old_password: string,
    new_password: string
): Promise<Response> {
    return genericSave("/api/account/change-password", {
        old_password: old_password,
        new_password: new_password,
    });
}

export async function petName(pet_id: string, name: string): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-name`, { name: name });
}

export async function petNames(names: string[]): Promise<Response> {
    return genericSave(`/api/account/save-pet-names`, { names: names });
}
export async function species(
    pet_id: string,
    species: string
): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-species`, { species: species });
}

export async function gender(
    pet_id: string,
    gender: string
): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-gender`, { gender: gender });
}

export async function dob(
    month: string,
    year: string,
    day?: string
): Promise<Response> {
    let payload = {
        month: month,
        year: year,
    };

    if (day) {
        payload["day"] = day;
    }

    return genericSave("/api/pet/save-dob", payload);
}

export async function age(pet_id: string, age: string): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-age`, {
        age: age,
    });
}

export async function picture(
    pet_id: string,
    picture: File
): Promise<Response> {
    let data = new FormData();
    data.append("picture", picture);

    return genericSave(`/api/pet/${pet_id}/save-picture`, data);
}

export async function snoutID(pet_id: string, image: File): Promise<Response> {
    let data = new FormData();
    data.append("image", image);

    return genericSave(`/api/pet/${pet_id}/save-snout-id`, data);
}

export async function breed(pet_id: string, breed: string): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-breed`, { breed: breed });
}

export async function coat_colours(
    pet_id: string,
    coat_colours: string
): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-coat-colours`, {
        coat_colours: coat_colours,
    });
}

export async function identifiable_features(
    pet_id: string,
    identifiable_features: string
): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-identifiable-features`, {
        identifiable_features: identifiable_features,
    });
}

export async function allergies(
    pet_id: string,
    allergies: string
): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-allergies`, {
        allergies: allergies,
    });
}

export async function medication(
    pet_id: string,
    medication: string
): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-medication`, {
        medication: medication,
    });
}

export async function notes(pet_id: string, notes: string): Promise<Response> {
    return genericSave(`/api/pet/${pet_id}/save-notes`, {
        notes: notes,
    });
}

export async function multiPetId(
    petId: string,
    tagId: string
): Promise<Response> {
    return genericSave("/api/account/set-multi-pet-scan-id", {
        pet_id: petId,
        tag_id: tagId,
    });
}

export async function paymentDetails(details: object): Promise<Response> {
    return genericSave("/api/account/save-payment-details", details);
}

export async function hideName(
    petId: string,
    shouldHide: boolean
): Promise<Response> {
    return genericSave(`/api/pet/${petId}/save-hide-name`, {
        should_hide: shouldHide,
    });
}

export async function primaryContact(
    petId: string,
    contactReference: string
): Promise<Response> {
    return genericSave(`/api/pet/${petId}/save-primary-contact`, {
        contact: contactReference,
    });
}

export async function checkout(
    checkout_id: string,
    values: Object,
    form: string
): Promise<Response> {
    let url = `/api/account/save-checkout/${checkout_id}`;

    let payload = {
        values: values,
        form: form,
    };

    return genericSave(url, payload);
}
