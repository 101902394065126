/** @format */

import $ from "jquery";
import { ComponentLoader } from "../AbstractComponent";
import { BumperComponent } from "../components/BumperComponent";
import { WideCarouselComponent } from "../components/WideCarouselComponent";

new ComponentLoader([BumperComponent, WideCarouselComponent]);

let featureInterval;
const items = $(".lp-features__item");
const images = $(".lp-features__images > img");

const handleFeature = () => {
    setFeatureInterval();

    items.on("click", e => {
        clearInterval(featureInterval);
        let index = items.index(e.currentTarget);
        highlightFeature(index);
    });
};

function highlightFeature(index: number) {
    items.filter(".active").removeClass("active");
    images.filter(".active").removeClass("active");

    items[index].classList.add("active");
    images[index].classList.add("active");
}

function setFeatureInterval() {
    featureInterval = setInterval(() => {
        let activeItem = items.filter(".active");
        let currentIndex = items.index(activeItem.get(0));
        let newIndex = currentIndex + 1 >= images.length ? 0 : currentIndex + 1;
        highlightFeature(newIndex);
    }, 5000);
}

function handleBumper() {
    let bumperInst = BumperComponent.getInst();

    if (!bumperInst) {
        return;
    }

    let dynamicButtons = $("[data-dynamic-button]");

    bumperInst.on("quantityChange", quantity => {
        let tagText = "Tag";

        if (quantity > 1) {
            tagText += "s";
        }

        dynamicButtons.each((idx, elem) => {
            let beforeText = elem.innerHTML;
            elem.innerHTML = beforeText.replace(/Tag(s)?/g, tagText);
        });
    });
}

const handleFAQs = () => {
    const activeClass = "active";
    const items = $(".lp-faqs__item");

    items.each(index => {
        items[index].addEventListener("click", () => {
            const isActive = items[index].classList.contains(activeClass);

            if (isActive) {
                items[index].classList.remove(activeClass);
            } else {
                items[index].classList.add(activeClass);
            }

            items.each(idx => {
                if (index !== idx) {
                    items[idx].classList.remove(activeClass);
                }
            });
        });
    });
};

const handleVideo = () => {
    const pages = $(".lp-videos__page__item");
    const container = $(".lp-videos__list > div");
    const left = $(".lp-videos__page .left");
    const right = $(".lp-videos__page .right");

    let index = 0;
    const total = pages.length;

    left?.on("click", () => {
        if (index === 0) {
            return;
        }

        pages[index].classList.remove("active");
        index = index - 1;
        pages[index].classList.add("active");
        let elemWidth = container.first().width();
        container.css("transform", `translateX(-${index * elemWidth}px)`);
    });

    right?.on("click", () => {
        if (index + 1 === total) {
            return;
        }

        pages[index].classList.remove("active");
        index = index + 1;
        pages[index].classList.add("active");
        let elemWidth = container.first().width();
        container.css("transform", `translateX(-${index * elemWidth}px)`);
    });
};

const handlePausePlay = () => {
    const videoElems = $("video");

    videoElems.on("click", e => {
        const videoElem = $(e.currentTarget);
        const videoParent = videoElem.parent();
        if (videoParent.is("[data-playing]")) {
            (videoElem.get(0) as HTMLVideoElement).pause();
            videoParent.removeAttr("data-playing");
        } else {
            videoParent.css("width", videoParent.outerWidth());
            (videoElem.get(0) as HTMLVideoElement).play();
            videoParent.attr("data-playing", "true");
        }
    });
};

export function setup() {
    handleFeature();
    handleBumper();
    handleFAQs();
    handleVideo();
    handlePausePlay();
}
