/** @format */

/*
 * The first element with [data-notification] will be shown after a
 * delay, and then when that notification is closed, it'll schedule
 * the next element (with [data-notification]) to be displayed in 66
 * seconds after that, if that sibling exists.
 */

import $ from "jquery";

class Notification {
    element: HTMLElement;
    next_of_kin: Notification | null = null;
    timer: number = 0;
    disappear_time: number = 5_000;
    timeout: number = 60_000;

    constructor(element: HTMLElement) {
        this.element = element;

        $(this.element).on("click", "[data-notification-hide-button]", () => {
            this.disappear(false);
        });

        let attrTimeout = $(this.element).attr("data-timeout");

        if (attrTimeout) {
            this.timeout = parseInt(attrTimeout);
        }

        let attrDisappear = $(this.element).attr("data-disappear");

        if (attrDisappear) {
            this.disappear_time = parseInt(attrDisappear);
        }
    }

    appear() {
        $(this.element).fadeIn();
        $(this.element).css("display", "flex");

        if (this.disappear_time) {
            setTimeout(() => {
                this.disappear();
            }, this.disappear_time);
        }
    }

    disappear(fade: boolean = true) {
        if (fade) {
            $(this.element).fadeOut();
        } else {
            $(this.element).hide();
        }

        if (this.timer || !this.next_of_kin) {
            return;
        }

        this.timer = window.setTimeout(() => {
            this.next_of_kin.appear();
        }, this.next_of_kin.timeout);
    }
}

export function setup(): void {
    let notifications: Array<Notification> = [];
    $("[data-notification]").each((index, element) => {
        let noti = new Notification(element);
        notifications.push(noti);
    });

    for (let i = 0; i < notifications.length; i++) {
        notifications[i].next_of_kin = notifications[i + 1];
    }

    setTimeout(() => {
        notifications[0].appear();
    }, notifications[0].timeout);
}
