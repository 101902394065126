/** @format */

import { AbstractComponent } from "../AbstractComponent";

export class CountdownComponent extends AbstractComponent {
    public static selector: string = "countdown-component";
    private secondsLeft: number = 0;
    private counting: boolean = true;

    public init(): void {
        this.secondsLeft = parseInt(this.componentElement.data("seconds"));
        setInterval(this.count.bind(this), 1000);
    }

    private count(): void {
        if (!this.secondsLeft || !this.counting) {
            return;
        }

        this.secondsLeft--;
        this.componentElement.text(this.getSecondsLeftText());
    }

    private getSecondsLeftText(): string {
        let minutesLeft = Math.floor(this.secondsLeft / 60);
        let hoursLeft = Math.floor(minutesLeft / 60);

        if (hoursLeft > 24) {
            let daysLeft = Math.floor(hoursLeft / 24);
            let hoursAfterDays = hoursLeft - daysLeft * 24;
            let daysText = `${daysLeft} day`;
            let hoursText = `${hoursAfterDays} hour`;

            if (daysLeft > 1) {
                daysText += "s";
            }

            if (hoursLeft > 1) {
                hoursText += "s";
            }

            return `${daysText}, ${hoursText}`;
        }

        if (minutesLeft > 60) {
            let minutesAfterHours = minutesLeft - hoursLeft * 60;
            let hoursText = `${hoursLeft} hour`;
            let minutesText = `${minutesAfterHours} minute`;

            if (hoursLeft > 1) {
                hoursText += "s";
            }

            if (minutesLeft > 1) {
                minutesText += "s";
            }

            return `${hoursText}, ${minutesText}`;
        }

        let secondsAfterMinutes = this.secondsLeft - minutesLeft * 60;
        let minutesText = `${minutesLeft} minute`;
        let secondsText = `${secondsAfterMinutes} second`;

        if (minutesLeft > 1) {
            minutesText += "s";
        }

        if (secondsAfterMinutes > 1) {
            secondsText += "s";
        }

        return `${minutesText}, ${secondsText}`;
    }
}
