/** @format */

import { refetch, RefetchOptions } from "./refetch";
import { check_response } from "./toolkit";
import { getCSRFToken } from "../toolkit";

async function genericPost(
    url: string,
    body?: object,
    extraOptions?: object
): Promise<object> {
    let options: RefetchOptions = {
        method: "post",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-CSRFToken": getCSRFToken(),
        },
        credentials: "same-origin",
    };

    if (extraOptions) {
        options = Object.assign(options, extraOptions);
    }

    if (body) {
        options["body"] = JSON.stringify(body);
    }

    return refetch(`${window.location.origin}${url}`, options)
        .then(check_response)
        .then(response => response.json());
}

export async function activate(petId: string): Promise<object> {
    return genericPost(`/api/pet/${petId}/activate`);
}

export async function activateFree(petId: string): Promise<object> {
    return genericPost(`/api/pet/${petId}/activate-free`);
}

export async function upgrade(): Promise<void> {
    let response: object;

    try {
        response = await genericPost(`/api/account/upgrade`);
    } catch (error) {
        throw new Error("Something went wrong, please try again later.");
    }

    if (response["error"]) {
        throw new Error(response["error"]);
    }
}

export async function sendLocationText(
    position: GeolocationPosition,
    petId: string
): Promise<object> {
    let body = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
    };

    return genericPost(`/api/pet/${petId}/send-location-text`, body);
}

export async function markPetMissing(petId: string): Promise<object> {
    return genericPost(`/api/pet/${petId}/mark-missing`);
}

export async function markPetFound(petId: string): Promise<object> {
    return genericPost(`/api/pet/${petId}/mark-found`);
}

export async function deleteAccount(): Promise<object> {
    return genericPost("/api/account/delete-account");
}

export async function createAccount(
    pets: string[],
    name?: string,
    email?: string,
    phone?: string,
    hide_name?: boolean,
    currency?: string,
    vet_first?: boolean,
    line_1?: string,
    city?: string,
    state?: string,
    postal_code?: string,
    billing_line_1?: string,
    billing_city?: string,
    billing_state?: string,
    billing_postal_code?: string,
    wait_for_fortifi: boolean = false,
    raw_values: object = null
): Promise<object> {
    let body = {
        pets: pets,
        name: name,
        email: email,
        hide_name: hide_name,
        currency: currency,
        wait_for_fortifi: wait_for_fortifi,
        vet_first: vet_first,
    };

    if (phone) {
        body["phone"] = phone;
    }

    if (line_1) {
        body["address"] = {
            line_1: line_1,
            city: city,
            state: state,
            postal_code: postal_code,
        };
    }

    if (billing_line_1) {
        body["billing_address"] = {
            line_1: billing_line_1,
            city: billing_city,
            state: billing_state,
            postal_code: billing_postal_code,
        };
    }

    if (raw_values) {
        body["checkout_values"] = raw_values;
    }

    return genericPost("/api/account/create-account", body);
}

export async function createBilling(
    payment_token: string,
    store_verification_token: string
): Promise<string> {
    let body = {
        payment_token: payment_token,
        store_verification_token: store_verification_token,
    };
    let responseJson = await genericPost("/api/account/create-billing", body);

    return responseJson["cardId"];
}

export async function createOrder(
    verification_token: string = ""
): Promise<object> {
    let body = {
        verification_token: verification_token,
    };

    return genericPost("/api/account/create-order", body);
}

export async function changePrivacyMode(): Promise<object> {
    return genericPost("/api/account/change-privacy-mode");
}

export async function setScanId(
    orderId: string,
    scanId: string
): Promise<boolean> {
    let body = {
        order_id: orderId,
        scan_id: scanId,
    };

    let response = await genericPost("/api/admin/set-scan-id", body);
    return response["more_pets"];
}

export async function deleteAddress(addressId: string): Promise<object> {
    let body = {
        id: addressId,
    };

    return genericPost("/api/account/delete-address", body);
}

export async function vefifyAddress(
    line1: string,
    city: string,
    state: string,
    postalCode: string,
    name?: string,
    line_2?: string,
    verify_token?: string
): Promise<object> {
    let body = {
        line_1: line1,
        city: city,
        state: state,
        postal_code: postalCode,
    };

    if (name) {
        body["name"] = name;
    }

    if (line_2) {
        body["line_2"] = line_2;
    }

    if (verify_token) {
        body["verify_token"] = verify_token;
    }

    return genericPost("/api/account/verify-address", body);
}

export async function deleteContact(contactRef: string): Promise<object> {
    let body = {
        ref: contactRef,
    };

    return genericPost("/api/account/delete-contact", body);
}

export async function setCallLink(contactId: string): Promise<string> {
    let body = {
        contact_reference: contactId,
    };

    let response = await genericPost(`/api/account/set-call-link`, body);

    return response["number"];
}

export async function processOrder(
    chargeId: string,
    product_codes: string[],
    reference: string,
    cardInfo: object,
    shipped: boolean = true
): Promise<object> {
    let body = {
        charge_id: chargeId,
        product_codes: product_codes,
        reference: reference,
        card_info: cardInfo,
        shipped: shipped,
    };

    return genericPost("/api/account/process-order", body);
}

export async function finalizeOrder(
    chargeId: string,
    orderFid: string
): Promise<object> {
    let body = {
        charge_id: chargeId,
        order_fid: orderFid,
    };

    return genericPost("/api/account/finalize-order", body);
}

export async function orderMoreTags(pets: string[]): Promise<object> {
    let body = {
        pets: pets,
    };

    return genericPost("/api/account/order-more-tags", body);
}

export async function autocompleteSearch(
    query: string,
    container?: string
): Promise<object[]> {
    let body = {
        query: query,
    };

    if (container) {
        body["container"] = container;
    }

    let response = await genericPost("/api/account/autocomplete-search", body);

    return response["results"];
}

export async function autocompleteDetails(placeId: string): Promise<object> {
    let response = await genericPost("/api/account/autocomplete-details", {
        place_id: placeId,
    });
    return response["details"];
}

export async function paypalUrl(
    product_codes: string[],
    reference: string,
    activatePetId?: string
): Promise<string> {
    let body = {
        product_codes: product_codes,
        reference: reference,
        return_url: window.location.href,
    };

    if (activatePetId) {
        body["activate_pet_id"] = activatePetId;
    }

    let data = await genericPost("/api/account/paypal-url", body);
    return data["url"];
}

export async function setPending(petId: string): Promise<object> {
    return genericPost(`/api/pet/${petId}/set-pending`);
}

export async function premakeOrder(): Promise<object> {
    return genericPost(`/api/account/premake-order`);
}

export async function reactivate(
    cardInfo: object,
    sameSubscription: boolean,
    chargeId?: string
): Promise<object> {
    let body = {
        card_info: cardInfo,
        same_subscription: sameSubscription,
    };

    if (chargeId) {
        body["charge_id"] = chargeId;
    }

    return genericPost("/api/account/reactivate", body);
}

export async function annualUpgrade(): Promise<object> {
    return genericPost("/api/account/annual-upgrade", {});
}

export async function checkPostcode(
    petId: string,
    postcode: string
): Promise<object> {
    return await genericPost(`/api/pet/${petId}/check-postcode`, {
        postcode: postcode,
    });
}

export async function cancelFlowDecision(decision: string): Promise<object> {
    return genericPost("/api/account/cancel-flow-decision", {
        decision: decision,
    });
}

export async function enableRenewal(): Promise<object> {
    return genericPost("/api/account/enable-renewal");
}

export async function addressesAtPostalCode(
    postal_code: string
): Promise<object[]> {
    let body = await genericPost("/api/account/addresses-at-postal-code", {
        postal_code: postal_code,
    });
    return body["addresses"];
}

export async function pixelAPI(
    event: string,
    eventId: string,
    value?: number
): Promise<object> {
    let body = {
        event: event,
        event_id: eventId,
        user_url: window.location.href,
    };

    if (value) {
        body["value"] = value;
    }

    return genericPost("/api/account/fire-pixels", body);
}

export async function loginEmail(email: string): Promise<object> {
    return genericPost(`/api/account/send-login-link/${email}`);
}

export async function getOrderQuantity(
    orderId: string,
    stationId: string
): Promise<object> {
    return genericPost(`/api/account/get-order-quantity`, {
        order_id: orderId,
        station_id: stationId,
    });
}

export async function setScanIds(
    orderId: string,
    scanIds: string[],
    name: string
): Promise<object> {
    return genericPost(`/api/account/set-scan-ids`, {
        order_id: orderId,
        scan_ids: scanIds,
        name: name,
    });
}

export async function reprintLabel(
    orderId: string,
    stationId: string
): Promise<object> {
    return genericPost(`/api/account/reprint-label`, {
        order_id: orderId,
        station_id: stationId,
    });
}

export async function unlinkTags(orderId: string): Promise<object> {
    return genericPost(`/api/account/unlink-tags`, {
        order_id: orderId,
    });
}

export async function checkTag(scanId: string): Promise<string> {
    let response = await genericPost(`/api/account/check-single-scan-id`, {
        scan_id: scanId,
    });

    return response["message"];
}

export async function replaceTags(scanIds: string[]): Promise<string> {
    let response = await genericPost(`/api/account/replace-tags`, {
        scan_ids: scanIds,
    });

    return response["message"];
}

export async function orderReturned(
    orderId: string,
    reason: string
): Promise<object> {
    return genericPost(`/api/account/order-returned`, {
        order_id: orderId,
        reason: reason,
    });
}

export async function sendAuthCode(): Promise<object> {
    return genericPost(`/api/account/send-auth-code`);
}

export async function verifyAuthCode(code: string): Promise<object> {
    return genericPost(`/api/account/verify-auth-code`, {
        code: code,
    });
}

export async function sendAuthCodeNoLogin(email: string): Promise<object> {
    return genericPost(`/api/account/send-auth-code-no-login`, {
        email: email,
    });
}

export async function verifyAuthCodeAndLogin(
    email: string,
    code: string,
    nonce: string = null
): Promise<object> {
    let body = {
        email: email,
        code: code,
    };

    if (nonce) {
        body["nonce"] = nonce;
    }

    return genericPost(`/api/account/verify-auth-code-and-login`, body);
}

export async function startVideoVetSubscription(
    planCode: string,
    offerCode?: string
): Promise<object> {
    let body = {
        plan_code: planCode,
    };

    if (offerCode) {
        body["offer_code"] = offerCode;
    }

    return genericPost(`/api/account/start-video-vet-subscription`, body);
}

export async function verifyEmail(email: string): Promise<object> {
    const body = { email: email };
    return genericPost("/api/account/verify-email", body);
}

export async function updateOneSignalUserId(
    onesignal_user_id: string
): Promise<object> {
    const body = {
        onesignal_user_id: onesignal_user_id,
    };
    return genericPost("/api/account/update-onesignal-user-id", body);
}

export async function provisionVidivetAccount(): Promise<object> {
    return genericPost("/api/account/provision-vidivet-account");
}
