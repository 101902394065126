import $ from "jquery";

const activeClass = 'active'
const hamburger = $('.navbar__hamburger')
const mobileMenu = $('.navbar__mobile')

hamburger.on('click', () => {
  if (hamburger.hasClass(activeClass)) {
    hamburger.removeClass(activeClass)
    mobileMenu.fadeOut();
    document.body.style.overflow = "auto";
  } else {
    hamburger.addClass(activeClass)
    mobileMenu.fadeIn();
    document.body.style.overflow = "hidden";
  }
})

const dashboardMenu = $(".dashboard-header__menu")
const dashboardSidebar = $(".dashboard-sidebar")
const dashboardSidebarOverlay = $(".dashboard-sidebar__overlay")

dashboardMenu.on('click', () => {
  const isActive = dashboardMenu.hasClass(activeClass)

  if(isActive){
    dashboardSidebarOverlay.removeClass(activeClass)
    dashboardMenu.removeClass(activeClass)
    dashboardSidebar.removeClass(activeClass)
  }else {
    dashboardSidebarOverlay.addClass(activeClass)
    dashboardMenu.addClass(activeClass)
    dashboardSidebar.addClass(activeClass)
  }
})

document.addEventListener('DOMContentLoaded', function () {
  const notification = $('[data-notification]')
  const notificationClose = $('[data-notification-close]')
  const dropdown = $(".dashboard-header__notification__dropdown")

  if (notification) {
    notification.on('click', () => {
      notification.toggleClass('active')
      dropdown.toggleClass('hide')
      dashboardSidebarOverlay.toggleClass('active')
    })
  }

  if (notificationClose) {
    notificationClose.on('click', () => {
      notification.toggleClass('active')
      dropdown.toggleClass('hide')
      dashboardSidebarOverlay.toggleClass('active')
    })
  }
})