/** @format */

import "../polyfill";
import $ from "jquery";
import "jquery-validation";
import "../extra_validators";

declare const grecaptcha: any;

function setupListeners(form: HTMLElement) {
    $(form).on("keydown", event => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    });
}

export function setup(form: HTMLElement) {
    ($(form) as any).validate({
        onKeyup: true,
        rules: {
            name: {
                required: true,
                fullname: true,
                minlength: 5,
            },
            from_email: {
                required: true,
                email: true,
            },
            message: {
                required: true,
                minlength: 5,
            },
        },
    });

    setupListeners(form);
}
