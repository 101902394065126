/** @format */

import { getContext } from "./toolkit";

let API_URL = "https://maps.googleapis.com/maps/api/js";
let CALLBACK_NAME = "__googleMapsApiOnLoadCallback";

let optionsKeys = ["channel", "client", "key", "language", "region", "v"];

let promise = null;

export async function loadGoogleMapsAPI(options = {}) {
    promise =
        promise ||
        new Promise(function(resolve, reject) {
            // Reject the promise after a timeout
            let timeoutId = setTimeout(function() {
                window[CALLBACK_NAME] = function() {}; // Set the on load callback to a no-op
                reject(new Error("Could not load the Google Maps API"));
            }, options["timeout"] || 10000);

            // Hook up the on load callback
            window[CALLBACK_NAME] = function() {
                if (timeoutId !== null) {
                    clearTimeout(timeoutId);
                }
                resolve(window.google.maps);
                delete window[CALLBACK_NAME];
            };

            // Prepare the `script` tag to be inserted into the page
            let scriptElement = document.createElement("script");
            let params = [`callback=${CALLBACK_NAME}`];
            optionsKeys.forEach(function(key) {
                if (options[key]) {
                    params.push(`${key}=${options[key]}`);
                }
            });
            if (options["libraries"] && options["libraries"].length) {
                params.push(`libraries=${options["libraries"].join(",")}`);
            }
            scriptElement.src = `${options["apiUrl"] || API_URL}?${params.join(
                "&"
            )}`;

            // Insert the `script` tag
            document.body.appendChild(scriptElement);
        });
    return promise;
}

let options = {
    apiUrl: "https://maps.googleapis.com/maps/api/js",
    key: "AIzaSyB4PWftPA44Z15euKe9S2XfJIwLI1CFPME",
};

export async function setup(): Promise<void> {
    let coords = getContext()["coords"];
    let zoom = 13;

    if (!coords["lat"] || !coords["lng"]) {
        coords = { lat: 52.639301, lng: -1.139512 };
        zoom = 5;
    }

    await loadGoogleMapsAPI(options);
    let center: google.maps.LatLngLiteral = coords;

    let map = new google.maps.Map(document.querySelector("[data-google-map]"), {
        zoom: zoom,
        center: center,
        disableDefaultUI: true,
        styles: [
            {
                elementType: "labels.icon",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
        ],
    });

    if (zoom == 5) {
        return;
    }

    new google.maps.Marker({
        map: map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: center,
    });
}
