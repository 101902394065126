/** @format */

import { refetch } from "./refetch";
import { check_response } from "./toolkit";

async function genericGet(url: string): Promise<Response> {
    return refetch(`${window.location.origin}${url}`)
        .then(check_response)
        .then(response => response.json());
}

export async function subscriptionLive(): Promise<boolean> {
    let body = await genericGet("/api/account/get-subscription-status");
    return body["live"];
}

export async function getOrdersInQueue(): Promise<number[]> {
    let body = await genericGet("/api/account/orders-in-queue");
    return body["ids"];
}

export async function chargehiveContext(
    random_reference: boolean = false,
    vet: boolean = false
): Promise<Response> {
    let url = new URL(
        "/api/account/chargehive-context",
        window.location.origin
    );

    if (random_reference) {
        url.searchParams.append("random_reference", "true");
    }

    if (vet) {
        url.searchParams.append("vet", "true");
    }

    return genericGet(url.pathname + url.search);
}
