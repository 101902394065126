/** @format */

import $ from "jquery";

export async function check_response(response: Response): Promise<Response> {
    if (!response.ok) {
        throw new NotOkResponseError(response);
    } else {
        return response;
    }
}

export class NotOkResponseError extends Error {
    response: Response;

    constructor(response: Response) {
        let url = response.url;
        let code = response.status;
        let description = response.statusText;
        super(`Response (${url}) was ${code} - ${description}`);

        this.response = response;
        this.name = "NotOkResponseError";
    }
}

export function emptyPromise() {
    // 😢
    return Promise.resolve();
}

export function get_endpoints(): { [url_name: string]: string } {
    let element = document.getElementById("js-endpoints");
    if (element) {
        return JSON.parse(element.textContent);
    } else {
        return {};
    }
}

export function addOptions(
    select: JQuery<HTMLElement>,
    options: object[]
): void {
    for (let option of options) {
        let optionElem = $("<option>", {
            value: option["id"],
        });
        optionElem.text(option["name"]);

        if (option["default"]) {
            optionElem.attr("selected", "");
        }

        select.append(optionElem);
    }
}
