/** @format */

import $ from "jquery";
import { ComponentLoader } from "../AbstractComponent";
import { BumperComponent } from "../components/BumperComponent";
import { WideCarouselComponent } from "../components/WideCarouselComponent";

new ComponentLoader([BumperComponent, WideCarouselComponent]);

const handleFAQs = () => {
    const activeClass = "active";
    const items = $(".order-faqs__item");

    items.each(index => {
        items[index].addEventListener("click", () => {
            const isActive = items[index].classList.contains(activeClass);

            if (isActive) {
                items[index].classList.remove(activeClass);
            } else {
                items[index].classList.add(activeClass);
            }

            items.each(idx => {
                if (index !== idx) {
                    items[idx].classList.remove(activeClass);
                }
            });
        });
    });
};

export const setup = () => {
    handleFAQs();
};
